<template>
  <div>
    <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" width="200"
      v-loading="loading">
      <el-table-column align="center" type="index" prop="UserID" label="序号" width="50"></el-table-column>
      <el-table-column align="center" prop="UserLoginPhone" label="注册ID" width="180" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="300" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="390" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="LegalPersonIName" label="姓名" width="140" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="LegalPersonIIDCard" label="身份证号" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="LegalPersonlPhone" label="手机号" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="BaseStatus" label="审核状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.BaseStatus == '审核通过'">{{
            scope.row.BaseStatus
          }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.BaseStatus }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="ConfigStatus" label="设置状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.ConfigStatus == '已设置'">{{ scope.row.ConfigStatus }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.ConfigStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ProviderStatus" label="同意状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.ProviderStatus == '已同意'">{{ scope.row.ProviderStatus }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.ProviderStatus }}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="HZStatus" label="客户状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.HZStatus == '已合作'">{{
            scope.row.HZStatus
          }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.HZStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="AgentType" label="代理分类" width="120"></el-table-column>
      <el-table-column align="center" prop="IsMultiterminal" label="是否启动多用户登录" width="260">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.IsMultiterminal" inactive-text="否" active-text="是"
            @change="changeIsMultiterminal(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="260">
        <template slot-scope="scope">
          <el-button type="text" @click="goFacility(scope.row, '/auditInfo/auditAgency/agencyInfo')">详情</el-button>
          <!-- <el-button type="text" @click="goFacility(scope.row, '/platform/alluser/pact')">合同</el-button> -->
          <el-button type="text" @click="examineRate(scope.row)">费率</el-button>
          <el-button type="text" @click="approveType(scope.row)">认证状态
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看费率 -->
    <!-- <el-dialog :visible.sync="flag.rateMdel" width="700px">
      <span slot="title">查看费率</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>代理商名称：</strong><u>{{ ownerInfo.EnterpriseFullName || ownerInfo.LegalPersonIName }}</u><br />
        </div>
        <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <div v-if="scope.row.TaskSceneName">
                <span v-if="scope.row.TaskSceneTypeID == 3">
                  {{ scope.row.InvoiceTypeName[1] }} / {{ scope.row.TaskTypeName }} / {{
                    scope.row.TaskSceneName
                  }}</span>
                <span v-else-if="scope.row.TaskSceneTypeID == 31">
                  {{ scope.row.InvoiceTypeName[0] }} / {{ scope.row.TaskTypeName }} / {{
                    scope.row.TaskSceneName
                  }}</span>
                <span v-else>
                  {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                    scope.row.TaskSceneName
                  }}</span>
              </div>
              <div v-else>
                <span v-if="scope.row.TaskSceneTypeID == 3">
                  {{ scope.row.InvoiceTypeName[1] }}</span>
                <span v-else-if="scope.row.TaskSceneTypeID == 31">
                  {{ scope.row.InvoiceTypeName[0] }}</span>
                <span v-else>
                  {{ scope.row.InvoiceTypeName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="数值" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneTypeID == 3">{{ scope.row.PP_Rate }}</span>
              <span v-else>{{ scope.row.Rate }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog> -->
    <!-- 查看费率 -->
    <el-dialog :visible.sync="flag.rateMdel" width="70%" title="查看费率" v-if="flag.rateMdel" class="deep_dialog">
      <el-tabs v-model="rateType" style="margin-top: 1vh">
        <el-tab-pane label="费率" name="common"></el-tab-pane>
        <el-tab-pane label="专项费率" name="special"></el-tab-pane>
      </el-tabs>
      <div v-if="rateType == 'common'">
        <div style="font-weight: bold; text-align: center; margin-bottom: 20px">
          代理商名称：{{
            ownerInfo.EnterpriseFullName || ownerInfo.LegalPersonIName
          }}
        </div>
        <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" :key="rateType"
          v-loading="loading1" max-height="500px">
          <el-table-column align="center" label="发票-业务-场景">
            <template slot-scope="scope">
              <span v-if="scope.row.TaskSceneName">
                {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
                {{ scope.row.TaskSceneName }}</span>
              <span v-else> {{ scope.row.InvoiceTypeName }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="数值" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.Rate ? scope.row.Rate + "%" : scope.row.Rate }}
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="toDetail(scope.row)"
                :disabled="!scope.row.RateID">查看历史记录</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-else>
        <el-table :data="extSupplierList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" max-height="450px"
          :key="rateType">
          <el-table-column align="center" label="企业名称" show-overflow-tooltip><template slot-scope="scope">{{
            scope.row.EnterpriseFullName
          }}</template></el-table-column>
          <el-table-column align="center" label="状态" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-tag :type="scope.row.Status == 10
                    ? 'primary'
                    : scope.row.Status == 1
                      ? 'danger'
                      : 'success'
                  ">{{ scope.row.StatusName }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column align="center" label="备注" show-overflow-tooltip><template slot-scope="scope">{{
            scope.row.Remark
          }}</template></el-table-column>
          <el-table-column align="center" label="操作" width="200">
            <template slot-scope="scope">
              <el-button type="primary" size="small" @click="watchRate(scope.row)">查看费率</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <!-- 分页 -->
          <el-pagination background class="pagination" @current-change="handleCurrentChange1"
            :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination1.total">
          </el-pagination>
        </div>
      </div>
    </el-dialog>
    <!-- 查看企业专项费率 -->
    <el-dialog :visible.sync="itemRateDialog" width="50%">
      <div style="font-weight: bold; text-align: center; margin-bottom: 20px">
        企业名称：{{ itemInfo.EnterpriseFullName }}
      </div>
      <el-table :data="itemRateList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" max-height="450px">
        <el-table-column align="center" prop="EnterpriseAddress" label="发票-业务-场景" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="scope.row.TaskSceneName">
              {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} /
              {{ scope.row.TaskSceneName }}</span>
            <span v-else> {{ scope.row.InvoiceTypeName }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="数值">
          <template slot-scope="scope">{{
            scope.row.Rate ? scope.row.Rate + "%" : scope.row.Rate
          }}</template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看费率历史记录 -->
    <el-dialog title="历史记录" :visible.sync="rateRecordDialog" width="40%" v-if="rateRecordDialog" class="rate-dialog">
      <div style="text-align: center; font-weight: bold; margin-bottom: 10px">
        发票-业务-场景：
        <span v-if="currentItem.TaskSceneName">
          {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} /
          {{ currentItem.TaskSceneName }}</span>
        <span v-else> {{ currentItem.InvoiceTypeName }}</span>
      </div>
      <el-table :data="rateRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading1">
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
          <template slot-scope="scope">{{
            scope.row.Rate ? scope.row.Rate + "%" : ""
          }}</template>
        </el-table-column>
        <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <el-pagination background class="pagination" @current-change="handleCurrentChange2"
          :current-page.sync="pagination2.page" :page-size="pagination2.pagesize"
          layout="total, prev, pager, next, jumper" :total="pagination2.total"></el-pagination>
      </div>
    </el-dialog>
    <!-- 认证状态 -->
    <el-dialog :visible.sync="flag.approveMdel" width="700px">
      <span slot="title">认证状态</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>货主名称：</strong><u>{{ ownerInfo.EnterpriseFullName }}</u><br />
        </div>
        <approvelist :sysConfigList="sysConfigList"></approvelist>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { GetThirdStatus } from "@/api/platform/ownerPricing/index";
import {
  GetAgentRate,
  GetAgentExtSupplier,
  GetAgentExtRate,
  GetRateRecords,
} from "@/api/newRate";
import { EditMultiterminal } from "@/api/common/common";
import approvelist from "@/components/businessCmpt/approvelist";
export default {
  props: ["tableData", "loading", "pageIndex"],
  data() {
    return {
      // 弹窗集合
      flag: {
        // 查看费率
        rateMdel: false,
        // 认证状态
        approveMdel: false,
      },
      // 货主信息
      ownerInfo: {},
      // 费率信息
      rateData: [],
      // 认证状态
      sysConfigList: [],
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination2: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      loading1: false,
      rateType: "common",
      extSupplierList: [],
      itemRateList: [],
      itemInfo: {},
      itemRateDialog: false,
      currentItem: "",
      rateRecordDialog: false,
      rateRecord: [],
    };
  },
  methods: {
    // 切换是否多用户登录
    changeIsMultiterminal(item) {
      EditMultiterminal({
        isMultiterminal: item.IsMultiterminal,
        userId: item.UserID,
      }).then((res) => {
        this.$message.success("设置成功");
      });
    },
    // 认证状态
    approveType(item) {
      this.ownerInfo = item;
      GetThirdStatus({ UserID: item.UserID }).then((res) => {
        this.sysConfigList = res.sysConfigList;
        this.flag.approveMdel = true;
      });
    },
    // 查看费率
    examineRate(item) {
      this.rateType = "common";
      this.ownerInfo = item;
      GetAgentRate({ userid: item.UserID }).then((res) => {
        let ratelist = [];
        if (res.data && res.data.length) {
          res.data.forEach((item) => {
            item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
            ratelist.push(item);
          });
        } else {
          this.$message.error("该代理商暂未设置费率");
          return;
        }
        this.rateData = ratelist;
        this.flag.rateMdel = true;
      });
      this.getAgentExtSupplier();
    },
    // 获取专项费率列表
    getAgentExtSupplier() {
      GetAgentExtSupplier({
        pageSize: this.pagination1.pagesize,
        pageIndex: this.pagination1.page,
        userID: this.ownerInfo.UserID,
        enterpriseFullName: "",
      }).then((res) => {
        this.extSupplierList = res.data.DataList;
        this.pagination1.total = res.data.TotalCount;
      });
    },
    watchRate(item) {
      this.itemInfo = item;
      this.loading1 = true;
      GetAgentExtRate({
        userID: this.ownerInfo.UserID,
        partnerUserID: item.UserID,
      })
        .then((res) => {
          let ratelist = [];
          if (res.data && res.data.length) {
            res.data.forEach((item) => {
              item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
              ratelist.push(item);
            });
          }
          this.itemRateList = ratelist;
          this.itemRateDialog = true;
        })
        .finally(() => {
          this.loading1 = false;
        });
    },
    // 查看历史记录
    toDetail(item) {
      this.currentItem = item;
      this.getRateRecords();
    },
    getRateRecords() {
      this.loading1 = true;
      GetRateRecords({
        pageSize: this.pagination2.pagesize,
        pageIndex: this.pagination2.page,
        rateID: this.currentItem.RateID,
      })
        .then((res) => {
          this.rateRecord = res.data.DataList;
          this.pagination2.total = res.data.TotalCount;
          this.rateRecordDialog = true;
        })
        .finally(() => {
          this.loading1 = false;
        });
    },
    handleCurrentChange1(e) {
      this.pagination1.page = e;
      this.getAgentExtSupplier();
    },
    handleCurrentChange2(e) {
      this.pagination2.page = e;
      this.getRateRecords();
    },
    // 查看详情
    goFacility(item, url) {
      this.$router.push({
        path: url,
        query: {
          UserID: item.UserID,
          pageType: "agency",
          companyName: item.EnterpriseFullName,
          urlType: "agency",
          pageIndex: this.pageIndex,
          title: "所有",
          isWait: 0,
        },
      });
    },
  },
  components: {
    approvelist,
  },
};
</script>

<style lang="scss" scoped>
.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 1%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}
</style>
