<template>
  <!-- 所有服务商 -->
  <div>
    <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading">
      <el-table-column type="index" align="center" label="序号" width="50"></el-table-column>
      <el-table-column align="center" prop="UserLoginPhone" label="注册ID" width="180" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="InviteCode" label="邀请码" width="120" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="200" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="220" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="LegalPersonIName" label="姓名" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="LegalPersonlPhone" label="手机号码" width="120" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="SubmitDatetime" label="提交时间" width="160" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="BaseStatus" label="审核状态" width="110">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.BaseStatus == '审核通过'">{{ scope.row.BaseStatus }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.BaseStatus }}</el-tag>
        </template>
      </el-table-column>
      <!-- <el-table-column align="center" prop="ConfigStatus" label="设置状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.ConfigStatus == '已设置'">{{ scope.row.ConfigStatus }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.ConfigStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ProviderStatus" label="同意状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.ProviderStatus == '已同意'">{{ scope.row.ProviderStatus }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.ProviderStatus }}</el-tag>
        </template>
      </el-table-column> -->
      <el-table-column align="center" prop="HZStatus" label="客户状态">
        <template slot-scope="scope">
          <el-tag type="success" v-if="scope.row.HZStatus == '已合作'">{{ scope.row.HZStatus }}</el-tag>
          <el-tag type="warning" v-else>{{ scope.row.HZStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="IsMultiterminal" label="是否启动多用户登录" width="260">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.IsMultiterminal" inactive-text="否" active-text="是"
            @change="changeIsMultiterminal(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="260">
        <template slot-scope="scope">
          <el-button type="text" @click="goFacility(scope.row, '/platform/alluser/serviceInfo')">详情</el-button>
          <el-button type="text" @click="goFacility(scope.row, '/platform/alluser/pact')">合同</el-button>
          <el-button type="text" @click="examineRate(scope.row)">费率</el-button>
          <el-button type="text" @click="approveType(scope.row)">认证状态
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 查看费率 -->
    <el-dialog :visible.sync="flag.rateMdel" width="71%" v-if="flag.rateMdel" class="rate-dialog">
      <span slot="title">查看费率</span>
      <el-tabs v-model="dialogName">
        <el-tab-pane label="服务商费率" name="first">
          <div class="dialog_box">
            <div class="dialogRole">
              <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
            </div>
            <div class="dialogRole">
              <strong>每月可开票最大金额：</strong><span style="color:orange">{{ TotalInvoiceAmount ? '￥'
                + TotalInvoiceAmount : 0 }}</span><br />
            </div>
            <el-table :data="rateData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading1"
              max-height="500px">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneName">
                    {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                      scope.row.TaskSceneName
                    }}</span>
                  <span v-else>
                    {{ scope.row.InvoiceTypeName }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="费率" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="技术服务费费率" show-overflow-tooltip>
                <template slot-scope="scope">
                  {{ scope.row.TecRate ? scope.row.TecRate + '%' : scope.row.TecRate }}
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button type="primary" @click="toDetail(scope.row)" :disabled="!scope.row.RateID">查看历史记录
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="代理商费率" name="second">
          <div class="dialog_box">
            <div class="dialogRole">
              <strong>企业名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
            </div>
            <el-table :data="rateDataAgent" :header-cell-style="{ background: '#f0f0f0', color: '#666' }"
              v-loading="loading1" max-height="500px">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneName">
                    {{ scope.row.InvoiceTypeName }} / {{ scope.row.TaskTypeName }} / {{
                      scope.row.TaskSceneName
                    }}</span>
                  <span v-else>
                    {{ scope.row.InvoiceTypeName }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="费率" show-overflow-tooltip>
                <template slot-scope="scope">
                  <span>{{ scope.row.Rate ? scope.row.Rate + '%' : scope.row.Rate }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="操作" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-button type="primary" @click="toDetail(scope.row)" :disabled="!scope.row.RateID">查看历史记录
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button @click="flag.rateMdel = false" size="small">关 闭</el-button>
      </span>
    </el-dialog>
    <!-- 查看费率历史记录 -->
    <el-dialog title="历史记录" :visible.sync="rateRecordDialog" width="40%" v-if="rateRecordDialog" class="rate-dialog">
      <div style="text-align: center;font-weight: bold;margin-bottom: 10px;">发票-业务-场景： <span
          v-if="currentItem.TaskSceneName">
          {{ currentItem.InvoiceTypeName }} / {{ currentItem.TaskTypeName }} / {{
            currentItem.TaskSceneName
          }}</span>
        <span v-else>
          {{ currentItem.InvoiceTypeName }}</span>
      </div>
      <el-table :data="rateRecord" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading1">
        <el-table-column type="index" align="center" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip>
          <template slot-scope="scope">{{ scope.row.Rate ? scope.row.Rate + '%' : '' }}</template>
        </el-table-column>
        <el-table-column align="center" prop="TecRate" label="技术服务费费率" show-overflow-tooltip v-if="dialogName == 'first'">
          <template slot-scope="scope">{{ scope.row.TecRate ? scope.row.TecRate + '%' : '' }}</template>
        </el-table-column>
        <el-table-column align="center" prop="CteatedTime" label="变更时间" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="margin-top:10px">
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize"
          layout="total, prev, pager, next, jumper" :total="pagination.total"></el-pagination>
      </div>
    </el-dialog>
    <!-- 认证状态 -->
    <el-dialog :visible.sync="flag.approveMdel" width="700px">
      <span slot="title">认证状态</span>
      <div class="dialog_box">
        <div class="dialogRole">
          <strong>货主名称：</strong><u>{{ serveInfo.EnterpriseFullName }}</u><br />
        </div>
        <approvelist :sysConfigList="sysConfigList"></approvelist>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { EditMultiterminal } from "@/api/common/common";
import { GetThirdStatus } from "@/api/platform/ownerPricing/index"
import {
  GetSupplierRate,
  GetRateRecords
} from "@/api/newRate";
import { _formatMoney } from "@/utils/utils";
import approvelist from "@/components/businessCmpt/approvelist";

export default {
  props: ["tableData", "loading", "pageIndex"],
  data() {
    return {
      // 弹窗集合
      flag: {
        // 查看费率
        rateMdel: false,
        // 认证状态
        approveMdel: false
      },
      dialogName: 'first',
      // 费率信息
      rateData: [],
      // 服务商信息
      serveInfo: {},
      // 认证状态
      sysConfigList: [],
      rateDataAgent: [],
      rateRecord: [],
      rateRecordDialog: false,
      currentItem: '',
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      loading1: false,
      TotalInvoiceAmount: 0, // 每月最大金额
    };
  },
  methods: {
    // 切换是否多用户登录
    changeIsMultiterminal(item) {
      EditMultiterminal({
        isMultiterminal: item.IsMultiterminal,
        userId: item.UserID
      }).then(res => {
        this.$message.success("设置成功")
      })
    },
    // 认证状态
    approveType(item) {
      this.serveInfo = item
      GetThirdStatus({ UserID: item.UserID }).then(res => {
        this.sysConfigList = res.sysConfigList
        this.flag.approveMdel = true;
      })
    },
    // 查看费率
    examineRate(item) {
      this.serveInfo = item
      this.dialogName = 'first'
      GetSupplierRate({ userid: item.UserID }).then(res => {
        let ratelist = []
        let eInvoiceTypeListAgent = []
        res.data.SupplierRate.forEach(item => {
          item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
          item.TecRate = (item.TecRate == 0 || item.TecRate == null) ? 0.3 : item.TecRate
          ratelist.push(item)
        })
        res.data.AgentRate.forEach(item => {
          item.Rate = (item.Rate == 0 || item.Rate == null) ? '' : item.Rate
          eInvoiceTypeListAgent.push(item)
        })
        this.TotalInvoiceAmount = _formatMoney(res.data.TotalInvoiceAmount)
        this.rateData = ratelist
        this.rateDataAgent = eInvoiceTypeListAgent
        this.flag.rateMdel = true;
      })
    },
    // 查看详情
    goFacility(item, url) {
      this.$router.push({
        path: url,
        query: {
          UserID: item.UserID,
          pageType: 'serve',
          companyName: item.EnterpriseFullName,
          urlType: 'first',
          pageIndex: this.pageIndex
        },
      });
    },
    // 查看历史记录
    toDetail(item) {
      this.currentItem = item
      this.getRateRecords()
    },
    getRateRecords() {
      this.loading1 = true
      GetRateRecords({
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        rateID: this.currentItem.RateID
      }).then(res => {
        this.rateRecord = res.data.DataList
        this.pagination.total = res.data.TotalCount;
        this.rateRecordDialog = true
      }).finally(() => {
        this.loading1 = false
      })
    },
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.getRateRecords();
    },
  },
  components: {
    approvelist
  }
};
</script>
<style lang="scss">
.rate-dialog {
  .el-dialog__body {
    padding-top: 0px;
  }
}
</style>
<style lang="scss" scoped>
.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 1%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>