<template>
  <!-- 所有司机 -->
  <div class="driverWrap">
    <el-table :data="tableData" :header-cell-style="{ background: '#f0f0f0', color: '#666' }" v-loading="loading"
      @selection-change="tableSelectionChange">
      <el-table-column type="selection" align="center" width="55" fixed></el-table-column>
      <el-table-column type="index" align="center" label="序号" fixed width="50"></el-table-column>
      <!-- <el-table-column align="center" prop="InviteCode" label="邀请码" width="120"></el-table-column> -->
      <el-table-column align="center" prop="DriverName" label="司机姓名" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="DriverIDCard" label="司机身份证号" width="160" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="Phone" label="司机手机号码" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="IsThreeElements" label="运营商实名校验" width="150" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.IsThreeElements" :type="scope.row.IsThreeElements == '未通过' ? 'danger' : 'success'">
            {{ scope.row.IsThreeElements }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="VerifyFlag" label="电子账户开立状态" width="130" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.VerifyFlag" :type="scope.row.VerifyFlag == '已开立' ? 'success'
            : scope.row.VerifyFlag == '开立失败' ? 'danger'
              : scope.row.VerifyFlag == '申请中' ? 'primary' : 'info'">
            {{ scope.row.VerifyFlag }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="DriverLicenseType" label="驾驶证类型" width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="DriverLicenseDateEnd" label="驾驶证有效期" width="180" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="DriverAttribution" label="归属类型" width="100" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="UserLoginPhone" label="登录手机号" width="180" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="VerifyDatetime" label="认证时间" width="180" show-overflow-tooltip>
      </el-table-column>
      <!-- <el-table-column align="center" prop="ReportStatus" label="上报状态" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.ReportStatus" :type="scope.row.ReportStatus == '上报成功' ? 'success' :
            scope.row.ReportStatus == '上报失败' ? 'danger' : 'primary'">
            {{ scope.row.ReportStatus }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="ReportUser" label="上报人" width="100" show-overflow-tooltip></el-table-column>
      <el-table-column align="center" prop="ReportRemark" label="上报备注" width="180" show-overflow-tooltip>
      </el-table-column>
      <el-table-column align="center" prop="ReportTime" label="上报时间" width="180" show-overflow-tooltip>
      </el-table-column> -->
      <el-table-column align="center" prop="YLCExist" label="平台运力池" width="100" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tag v-if="scope.row.YLCExist" :type="scope.row.YLCExist == '不存在' ? 'danger' : 'success'">
            {{ scope.row.YLCExist }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="IsMultiterminal" label="是否启动多用户登录" width="260">
        <template slot-scope="scope">
          <el-switch v-model="scope.row.IsMultiterminal" inactive-text="否" active-text="是"
            @change="changeIsMultiterminal(scope.row)">
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" fixed="right" width="330">
        <template slot-scope="scope">
          <el-button type="text" @click="preOpenDriver(scope.row)" v-if="!scope.row.VerifyFlagCode">开户
          </el-button>
          <el-button type="text" @click="relationClick(scope.row)">挂靠
          </el-button>
          <el-button type="text" @click="companyClick(scope.row, '/platform/alluser/driverInfo', 'info')">编辑</el-button>
          <el-button type="text" @click="companyClick(scope.row, '/platform/alluser/pact', 'pact')">合同</el-button>
          <el-button type="text" @click="goFacility(scope.row)">
            运单
          </el-button>
          <!-- <el-button :disabled="scope.row.ReportStatus == '上报成功' ? true : false" type="text"
            @click="upTraffic(scope.row)">上报</el-button> -->
          <el-button type="text" @click="handleClickDeleteDriver(scope.row)" style="color:#F56C6C">删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 运单统计 -->
    <el-dialog :title="DriverName" :visible.sync="centerDialogVisible" width="1200px" class="deep_dialog">
      <span slot="title">运单统计</span>
      <div>
        <el-table :data="driverlist" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
          <el-table-column type="index" align="center" fixed="left" label="序号" width="50" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="WaybillID" fixed="left" label="运单号（自动生成，请勿填写）" width="210"
            show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverName" label="司机姓名" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
                {{ scope.row.DriverName }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverPhone" label="司机手机号码" width="110" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
                {{ scope.row.DriverPhone }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="150" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
                {{ scope.row.DriverIDCard }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="PayeeName" label="收款人姓名" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeePhone" label="收款人手机号码" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="150" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="CarNumber" label="车牌" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="checkInfo(scope.row)">
                {{ scope.row.CarNumber }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="LoadNetWeight" label="装货净重（吨）" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="GoodsName" label="货品名称" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="GoodsUnit" label="货品单位" show-overflow-tooltip></el-table-column>
          <el-table-column align="center" prop="LoadingDatetime" label="装货时间" width="160" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="SignDatetime" label="签收时间" width="160" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OriginAddress" label="起始地" width="300" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                {{ scope.row.OriginAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ArriveAddress" label="到达地" width="300" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
                {{ scope.row.ArriveAddress }}
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="DriverAllotCount" label="司机装货数量" width="100" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="DriverCarryPrice" label="司机运输单价(人民币)" width="200" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.DriverCarryPrice | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="CarriageTotalPrice" label="运费" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.CarriageTotalPrice | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="100" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.ExpensesOfTaxation | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="NeedPayMoney" label="运单总金额" width="110" show-overflow-tooltip>
            <template slot-scope="scope">
              <div style="color: #ff8b17">{{ scope.row.NeedPayMoney | formatMoney }}</div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="UnloadNetWeight" label="卸货净重（吨）" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="OriginalWayBillID" label="溯源运单号" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column align="center" prop="Remark" label="风控路径" width="180" show-overflow-tooltip>
          </el-table-column>
        </el-table>
        <div style="margin-top: 10px">
          <!-- 分页 -->
          <el-pagination background class="pagination" @current-change="handleCurrentChange"
            :current-page.sync="pagination.page" :page-size="pagination.pagesize"
            layout="total, prev, pager, next, jumper" :total="pagination.total">
          </el-pagination>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="centerDialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看地图 -->
    <el-dialog width="800px" :visible.sync="flag.showMap" class="deep_dialog" append-to-body>
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="flag.showMap" ref="map" :list="mapList">
      </TXmap>
    </el-dialog>
    <!-- 车辆信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.carType" append-to-body class="deep_dialog">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.carType = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.showDriver" append-to-body class="deep_dialog">
      <span slot="title">人员信息</span>
      <DriverInfo :driverInfo="driverInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.showDriver = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 挂靠关系 -->
    <el-dialog dialog :title="`${vehicleInfo.DriverName}的挂靠关系`" :visible.sync="flag.isRelation" width="1100px">
      <relationList :relationList="relationList" :relationTotal="relationTotal" @relationChange="relationChange">
      </relationList>
    </el-dialog>
    <!-- 删除司机 -->
    <el-dialog title="删除原因" :visible.sync="flag.deleteDriverDialog" center width="600">
      <el-input v-model="errorMsg" type="textarea" maxlength="100" placeholder="请填写删除原因，至少两个字" style="margin-top: 30px">
      </el-input>
      <div style="display: flex; justify-content: center">
        <el-button type="primary" @click="deleteDriver" style="margin-top: 30px">确认删除</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap"
import relationList from "@/components/businessCmpt/relationlist"
import { GetWayBillAll, GetTCDriverRelation, deleteTCDriver } from "@/api/auditInfo/certified/index"
import { getMapPath, getDriverDetails, getCarInfo, upTrafficDriver, PreOpenDriver,EditMultiterminal } from "@/api/common/common"
import VehicleInfo from "@/components/businessCmpt/vehicleInfo"
import DriverInfo from "@/components/businessCmpt/driverInfo"
import { _getBase64 } from "@/utils/utils"
export default {
  props: ["tableData", "loading", "pageIndex"],
  data() {
    return {
      // 司机姓名
      DriverName: "",
      // 是否显示运单统计
      centerDialogVisible: false,
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 运单统计列表
      driverlist: [],
      // 是否显示地图弹窗
      flag: {
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
        // 预览
        isPreview: false,
        // 挂靠关系
        isRelation: false,
        deleteDriverDialog: false, //删除司机dialog
      },
      // 下载单个电子回单
      item: "",
      // 轨迹列表
      mapList: [],
      // 车辆信息
      vehicleInfo: {},
      // 司机信息
      driverInfo: {},
      driverLoading: true,
      relationList: '',
      relationTotal: 0,
      relationIndex: 1,
      currentDriver: null, //当前操作的司机对象
      errorMsg: '', //删除司机填写的错误信息
    };
  },
  methods: {
    // 切换是否多用户登录
    changeIsMultiterminal(item) {
      EditMultiterminal({
        isMultiterminal: item.IsMultiterminal,
        userId: item.UserID
      }).then(res => {
        this.$message.success("设置成功")
      })
    },
    //打开司机删除弹框
    handleClickDeleteDriver(item) {
      this.flag.deleteDriverDialog = true
      this.currentDriver = item
    },
    //删除司机
    deleteDriver() {
      if (this.errorMsg.length < 2) {
        this.$message.error('请填写删除原因')
        return
      }
      let params = {
        driverUserId: this.currentDriver.UserID,
        remark: this.errorMsg,
      }
      deleteTCDriver(params).then(res => {
        this.$emit('refreshList')
        this.$message.success('删除成功')
        this.flag.deleteDriverDialog = false
      })
    },
    // 开立电子账户
    preOpenDriver(row) {
      this.$confirm('确定要开立电子账户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const loading = this.$loading({
          lock: true,
          text: '开户中...',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
        PreOpenDriver({
          userid: row.UserID
        }).then(res => {
          this.$emit('refreshList')
          this.$message.success('开户成功');
        }).finally(() => {
          loading.close()
        })
      })
    },
    // 上报
    upTraffic(item) {
      let data = [{
        id: item.DriverIDCard
      }]
      this.$confirm('是否上报该条数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        upTrafficDriver(data).then(res => {
          this.$message({
            message: '操作成功',
            type: 'success'
          });
        }).finally(() => {
          this.$emit('GetPlatFormBy')
        })
      })
    },
    // 挂靠关系分页
    relationChange(e) {
      this.relationIndex = e
      this.relationClick(this.vehicleInfo)
    },
    // 挂靠关系
    relationClick(item) {
      this.vehicleInfo = item
      let data = {
        DriverIDCard: item.DriverIDCard,
        PageSize: 10,
        PageIndex: this.relationIndex
      }
      GetTCDriverRelation(data).then(res => {
        if (res.data.DataList.length != 0) {
          this.relationList = res.data.DataList
          this.relationTotal = res.data.TotalCount
          this.flag.isRelation = true;
        } else {
          this.$message.error('暂未找到挂靠关系');
        }
      })
    },
    //tbale选中改变
    tableSelectionChange(e) {
      this.$emit("tableSelectionChange", e)
    },
    // 查看详情
    companyClick(item, url, type) {
      let data = {
        pageIndex: this.pageIndex
      }
      if (type == 'pact') {
        data.UserID = item.UserID
        data.pageType = 'driver',
          data.urlType = 'third'
        data.companyName = item.EnterpriseFullName
      } else if (type == 'info') {
        data.DriverIDCard = item.DriverIDCard
        data.urlType = 'third'
      }
      this.$router.push({
        path: url,
        query: data,
      });
    },
    // 查看司机信息
    checkUser(item) {
      this.loading = true
      let data = {}
      getDriverDetails({ UserID: item.DriverUserID,AscriptionUserID: item.UserID }).then((res) => {
        data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo)
        this.driverInfo = data
        this.flag.showDriver = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading = true
      let params = {
        CarNumber: item.CarNumber
      }
      let data = {}
      getCarInfo(params).then(res => {
        data = Object.assign({}, res.data.vehicle_baseInfo, res.data.vehicle_confirmInfo)
        this.vehicleInfo = data
        this.flag.carType = true;
      }).finally(() => {
        this.loading = false
      });
    },
    //打开地图
    openMap(item) {
      this.loading = true
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
        wayBillId: item.WaybillID
      };
      getMapPath({ json: JSON.stringify(params) }).then((res) => {
        this.mapList = res.Track;
        this.flag.showMap = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 查看运单统计
    goFacility(item) {
      this.item = item;
      this.driverlist = []
      this.$emit('showLoading')
      this.GetWayBillAll('show');
    },
    // 运单统计数据
    GetWayBillAll(type) {
      console.log(this.item)
      this.driverLoading = true;
      let data = {
        DriverUserID: this.item.UserID,
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize
      };
      GetWayBillAll({ request: JSON.stringify(data) }).then((res) => {
        console.log(res)
        this.driverLoading = false;
        this.centerDialogVisible = true;
        this.driverlist = res.data.DataList;
        this.pagination.total = Number(res.data.TotalCount);
      }).finally(() => {
        if (type) {
          this.$emit('showLoading')
        }
      })
    },
    // 分页
    handleCurrentChange(e) {
      this.driverLoading = true;
      this.pagination.page = e;
      this.GetWayBillAll();
    },
  },
  watch: {
    'flag.deleteDriverDialog'(val) {
      if (!val) {
        this.errorMsg = ''
      }
    }
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
    relationList
  },
};
</script>

<style lang="scss">
.driverWrap {
  .el-divider--horizontal {
    height: 50px;
    background-color: #fff;
    margin: 0;

    .el-divider__text.is-center {
      top: 24px;
    }
  }

  .el-dialog__body {
    padding: 0 20px 30px 20px;
  }
}
</style>

<style lang="scss" scoped>
.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .upBox {
    text-align: center;

    .upText {
      font-size: 14px;
      font-weight: bold;
      margin: 10px 0;
      color: #333333;
    }

    .img {
      width: 350px;
      height: 220px;
      border-radius: 10px;
    }
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>